import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors } from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function Index() {
  const nombreOperacion = "Estadísticas";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [usuarios, setUsuarios] = useState([]);
  const [ingresosPorDia, setIngresosPorDia] = useState([]);
  const [valorInventario, setValorInventario] = useState(0);
  const [rankingProductos, setRankingProductos] = useState([]);
  const [rankingClientes, setRankingClientes] = useState([]);
  const [ingresosTotales, setIngresosTotales] = useState(0);
  const [ingresosMensuales, setIngresosMensuales] = useState([]);

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("estadisticas_administracion/estadisticas/", {}, "Estadísticas", "No se han podido cargar las estadísticas.").then((json) => {
      setUsuarios(json.usuarios);
      setIngresosPorDia(json.ingresos_por_dia);
      setValorInventario(json.valor_inventario);
      setRankingProductos(json.ranking_productos);
      setRankingClientes(json.ranking_clientes);
      setIngresosTotales(json.ingresos_totales);
      setIngresosMensuales(json.ingresos_mensuales);
      setEstado(ESTADOS.CARGADO);
    });
  }, []);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <h3>Comisiones vendedores hoy</h3>
        <div className="row g-3 mb-3">
          {usuarios.map((usuario) => (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">{usuario.nombre}</h5>
                  <p className="display-3 mb-1">{usuario.comision}</p>
                  <p className="mb-0">{usuario.fecha.split("-").reverse().join("/")}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <h3>Ventas</h3>
        <div className="row g-3 mb-3">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-1">Ingresos totales últimos 30 días</h5>
                <p className="display-6 mb-1">S/ {Number(ingresosTotales).toLocaleString()}</p>
              </div>
              <div class="card-footer  text-center">Es la suma de todos los ingresos percibidos por ventas de los últimos 30 días</div>
            </div>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-12 col-xl-7">
            <div className="card">
              <div className="card-body" style={{ display: "inline-block" }}>
                <h5 className="card-title mb-1">Valor totales de ventas por día (S/)</h5>
                <Line
                  style={{ width: 16, height: 9 }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ingresosPorDia.map((dia) =>
                      new Date(dia.fecha).toLocaleDateString("es-ES", { weekday: "short", day: "2-digit", month: "2-digit" }).replace(/\//g, "/")
                    ),
                    datasets: [
                      {
                        label: "Ingresos por día",
                        data: ingresosPorDia.map((dia) => dia.total),
                        fill: true,
                        borderColor: "#198754",
                        tension: 0.5,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-xl-5">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-1">Ingresos últimos meses</h5>
                <div className="table-responsive overflow-auto" style={{ height: 300 }}>
                  <table class="table">
                    <thead className="position-sticky">
                      <tr>
                        <th scope="col">Mes</th>
                        <th scope="col">Ingresos</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ingresosMensuales.map((mes, index) => (
                        <tr key={index}>
                          <td>
                            {new Date(new Date().getFullYear(), mes.mes - 1, 1)
                              .toLocaleString("es-ES", { month: "long" })
                              .replace(/^\w/, (c) => c.toUpperCase())}
                          </td>
                          <td>S/ {Number(mes.total).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Productos</h3>
        <div className="row g-3 mb-3">
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-1">Ranking de ventas por producto últimos 30 días</h5>
                <div className="table-responsive overflow-auto" style={{ height: 300 }}>
                  <table class="table">
                    <thead className="position-sticky">
                      <tr>
                        <th scope="col">Producto</th>
                        <th scope="col">Cantidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rankingProductos.map((producto) => (
                        <tr key={producto.id_producto}>
                          <td>{producto.nombre}</td>
                          <td>{producto.cantidad}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-1">Valor total de inventario</h5>
                <p className="display-6 mb-1">S/ {Number(valorInventario).toLocaleString()}</p>
              </div>
              <div class="card-footer text-center">Es la cantidad total de stock multiplicada por sus respectivos precios de venta</div>
            </div>
          </div>
        </div>
        <h3>Clientes</h3>
        <div className="row g-3 mb-3">
          <div className="col-12 col-xl-8">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-1">Ranking de ingresos por cliente últimos 30 días</h5>
                <div className="table-responsive overflow-auto" style={{ height: 300 }}>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Perfil</th>
                        <th scope="col">Cliente</th>
                        <th scope="col">Ingreso</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rankingClientes.map((cliente) => (
                        <tr key={cliente.id_cliente}>
                          <td>
                            <Link to={"../xd03/perfil/" + cliente.id_cliente} className="btn btn-sm btn-primary">
                              <i className="bi bi-person-lines-fill"></i>
                            </Link>
                          </td>
                          <td>{cliente.nombre}</td>
                          <td>S/ {Number(cliente.ingreso).toLocaleString()}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <main className="container-xl p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
