// import logo from "./logo.svg";
// import "./App.css";

import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { API_URL } from "./config";
import { usePeticion } from "./herramientas";

function App(props) {
  const navigate = useNavigate();
  const [estado, setEstado] = useState("cargando");
  const [operaciones, setOperaciones] = useState([]);
  const peticion = usePeticion();

  function handleCerrarSesion(e) {
    e.preventDefault();
    fetch(API_URL + "cerrar_sesion/", {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("token"),
      }),
    });
    localStorage.removeItem("token");
    navigate("/iniciar_sesion");
  }

  useEffect(() => { 
    peticion("operaciones_usuarios/", {}).then((json) => {
      let asd = []
      let operacionAnt 
      json.operaciones.forEach(operacion => {
        if (operacion.modulo != operacionAnt) {
          asd.push(<hr className="my-1" />)
        }
        asd.push(<li className="nav-item" key={operacion.codigo} data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
        <NavLink className="nav-link" to={operacion.codigo}>
          <i className={operacion.grafico_i}></i> {operacion.nombre}
        </NavLink>
      </li>)
        operacionAnt = operacion.modulo
      });
      setOperaciones(asd);
      setEstado("cargado");
    });

  }, []);

  return (
    <>
      <nav className="navbar navbar-dark bg-dark fixed-top">
        <div className="container-fluid">
          <span className="navbar-brand mb-0 h1">Importaciones Briju</span>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="offcanvas offcanvas-end bg-dark text-bg-dark" tabIndex={-1} id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
            <div className="offcanvas-header text-center">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">
                Importaciones Briju
              </h5>
              <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
                  <NavLink className="nav-link" to="/smen">
                    <i className="bi bi-house"></i> Inicio
                  </NavLink>
                </li>
                {operaciones.map(operacion=>operacion)}
                <li className="nav-item" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-label="Close">
                  <a role="button" className="nav-link" onClick={handleCerrarSesion}>
                    <i className="bi bi-x-circle"></i> Cerrar sesión
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div style={{height: "3.5rem"}}></div>
      {/* <Outlet /> */}
      {props.children}
    </>
  );
}

export default App;
