import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Exportar clientes";

  const [archivo, setArchivo] = useState("");
  const [estado, setEstado] = useState(ESTADOS.CARGADO);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function generar() {
    setEstado(ESTADOS.CARGANDO);
    peticion("zxd03/proceso/")
      .then((json) => {
        setArchivo(json.archivo);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No ha podido generarse el archivo: " + e);
        setEstado(ESTADOS.CARGADO);
      });
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <div className="row">
        <p>Genere el archivo de clientes para posteriormente porder descargarlo.</p>
        <div className="col">
          {estado == ESTADOS.CARGADO ? (
            <button type="button" onClick={generar} className="btn btn-success col-auto me-3 mb-3">
              <i className="bi bi-table"></i> Generar archivo
            </button>
          ) : (
            <button id="botonGenerando" className="btn btn-success me-3 mb-3" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Generando...
            </button>
          )}
          <a
            id="botonDescarga"
            role="button"
            href={archivo}
            download="clientes.xlsx"
            className={"btn me-3 mb-3 btn-success col-auto " + (archivo == "" ? "disabled" : "")}
          >
            <i className="bi bi-download"></i> Descargar
          </a>
        </div>
      </div>
    </main>
  );
}
