import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Agregar ítem a pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [productos, setProductos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();
  const peticion = usePeticion();

  const { id_pedido } = useParams();

  useEffect(() => {
    peticion("va02/agregar_item/", {})
      .then((json) => {
        setProductos(json.productos);
        setProductosFiltrados(json.productos);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los productos correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  useEffect(() => {
    let filtrado = productos.filter((fila) => {
      if (fila.nombre.toUpperCase().includes(busqueda.toUpperCase())) {
        return true;
      }
      return false;
    });
    setProductosFiltrados(filtrado);
  }, [busqueda]);

  function handleSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    formData.append("id_pedido", id_pedido)
    peticion("va02/agregar_articulo_pedido_proceso/", {
      method: "POST", body: formData
    }).then((json)=>{
      if (json.inventario == "alcanza") {
        navigate("../modificar/"+id_pedido)
      } else {
        alert("No hay suficiente inventario para este producto")
      }
    }).catch(e=>alert("No se ha podido agregar el ítem al pedido: "+e))
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="7">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = productosFiltrados.map((producto) => (
      <tr>
        <td className="align-middle text-center">
          <input
            required
            type="radio"
            className="btn-check"
            value={producto["id_producto"]}
            name="id_producto"
            id={"id_producto_" + producto["id_producto"]}
            autocomplete="off"
          />
          <label className="btn btn-outline-primary" htmlFor={"id_producto_" + producto["id_producto"]}>
            Selec
          </label>
        </td>
        <td className="align-middle text-center">
          <img alt="" src={API_URL + "static/img/productos/" + producto["id_producto"] + "/principal.jpeg"} className="rounded" style={{ height: "5rem" }} />
        </td>
        <td className="align-middle text-center">{producto["nombre"]}</td>
        <td className="align-middle text-center">{producto["cantidad_buen_estado"]}</td>
        <td className="align-middle text-center">{producto["cantidad_para_liquidacion"]}</td>
        <td className="align-middle text-center">{producto["cantidad_merma"]}</td>
        <td className="align-middle text-center">{producto["precio_final"]}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <div className="col">
            <label for="cantidad_buen_estado" className="form-label">
              Buen estado
            </label>
            <input type="number" min="0" step="0" className="form-control text-center" id="cantidad_buen_estado" name="cantidad_buen_estado" defaultValue={1} />
          </div>
          <div className="col">
            <label for="cantidad_para_liquidacion" className="form-label">
              Para liquidación
            </label>
            <input
              type="number"
              min="0"
              step="0"
              className="form-control text-center"
              id="cantidad_para_liquidacion"
              name="cantidad_para_liquidacion"
              defaultValue={0}
            />
          </div>
          <div className="col">
            <label for="cantidad_merma" className="form-label">
              Merma
            </label>
            <input type="number" min="0" step="1" className="form-control text-center" id="cantidad_merma" name="cantidad_merma" defaultValue={0} />
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-sm">
            <button type="submit" className="btn btn-success w-100 h-100">
              Agregar ítem
            </button>
          </div>
          <div className="col-sm">
            <button type="button" className="btn btn-danger w-100 h.100" onClick={() => navigate(-1)}>
              Cancelar
            </button>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col-sm">
            <input
              placeholder="Buscar producto por nombre..."
              type="text"
              className="form-control"
              id="busqueda"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
            />
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead>
              <tr>
                <th className="align-middle text-center" scope="col">
                  Selec
                </th>
                <th className="align-middle text-center" scope="col">
                  Imagen
                </th>
                <th className="align-middle text-center" scope="col">
                  Nombre
                </th>
                <th className="align-middle text-center" scope="col">
                  Buen estado
                </th>
                <th className="align-middle text-center" scope="col">
                  Para liquidación
                </th>
                <th className="align-middle text-center" scope="col">
                  Merma
                </th>
                <th className="align-middle text-center" scope="col">
                  Precio
                </th>
              </tr>
            </thead>
            <tbody id="tabla">{contenido}</tbody>
          </table>
        </div>
      </form>
    </main>
  );
}
