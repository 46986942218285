import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";

export default function Index() {
  const nombreOperacion = "Modificar ítem de pedido";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [articulo, setArticulo] = useState({});

  const navigate = useNavigate();
  const peticion = usePeticion();

  const { id_articulo_pedido } = useParams();

  useEffect(() => {
    peticion("va02/modificar_item/" + id_articulo_pedido, {})
      .then((json) => {
        setArticulo(json.articulo)
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los productos correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    formData.append("id_articulo_pedido", id_articulo_pedido);
    peticion("va02/consultar_numero_operacion/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        if (json.repetido) {
          let cadena = ""
          for (const pedido in json.pedidos) {
            cadena = cadena + json.pedidos[pedido].id_pedido + ", "
          }

          if(window.confirm(`Este número de operación ya está repetido en el(los) pedido(s) de número: ${cadena} ¿Igual desea proceder a establecerlo?`)) {
            peticion("va02/modificar_articulo_pedido_proceso/", {
              method: "POST",
              body: formData,
            })
              .then((json) => {
                navigate("../modificar/" + articulo.id_pedido);
              })
              .catch((e) => alert("No se ha podido agregar el ítem al pedido: " + e));
          }
        } else {
          peticion("va02/modificar_articulo_pedido_proceso/", {
            method: "POST",
            body: formData,
          })
            .then((json) => {
              navigate("../modificar/" + articulo.id_pedido);
            })
            .catch((e) => alert("No se ha podido agregar el ítem al pedido: " + e));
        }
      })
      .catch((e) => alert("No se ha podido agregar el ítem al pedido: " + e));
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <form onSubmit={handleSubmit}>
        <div className="row g-3 mb-3">
          <Form.Group className="col-md-6">
            <Form.Label>Nº de operación</Form.Label>
            <Form.Control type="text" inputMode="numeric" name="numero_operacion" defaultValue={articulo.numero_operacion} />
          </Form.Group>
          <Form.Group className="col-md-6">
            <Form.Label>Estado de pago</Form.Label>
            <Form.Select name="pagado" defaultValue={articulo.pagado}>
              <option value="0">No pagado</option>
              <option value="1">Pagado</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="col-12">
            <Button type="submit" variant="warning" className="mb-3 me-3">Modificar</Button>
            <Link role="button" className="btn btn-secondary mb-3 me-3" to={"../modificar/"+articulo.id_pedido}>Cancelar</Link>
          </Form.Group>
        </div>
      </form>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
