import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Declarar entrega";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [pedidos, setPedidos] = useState([]);
  const [pedidosFiltrados, setPedidosFiltrados] = useState([])
  const [busqueda, setBusqueda] = useState("")

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("vl01n/pedidos/", {})
      .then((json) => {
        setPedidos(json.pedidos)
        setPedidosFiltrados(json.pedidos)
        setEstado(ESTADOS.CARGADO)
      })
      .catch(e=>{
        alert("No han podido ser cargados los pedidos completados correctamente: "+e.message);
        setEstado(ESTADOS.CARGADO)
      })
  }, []);

  useEffect(()=>{
    let filtrado = pedidos.filter((fila)=>{
      if (fila.nombre_cliente.toUpperCase().includes(busqueda.toUpperCase()) || (new Date(fila.fecha)).toLocaleString().includes(busqueda.toUpperCase()) || fila.nombre_vendedor.toUpperCase().includes(busqueda.toUpperCase())) {
        return true;
      }
      return false;
    })
    setPedidosFiltrados(filtrado)
  }, [busqueda])

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="5">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = pedidosFiltrados.map((pedido) => (
      <tr>
        <td className="align-middle text-center">
          <Link to={"declarar_entrega/" + pedido.id_pedido} role="button" className="btn btn-primary">
            Seleccionar
          </Link>
        </td>
        <td className="align-middle text-center">{pedido.id_pedido}</td>
        <td className="align-middle text-center">{`${new Date(pedido["fecha"]).getUTCDate()}/${new Date(pedido["fecha"]).getUTCMonth() + 1}/${new Date(
                    pedido["fecha"]
                  ).getUTCFullYear()}`}</td>
        <td className="align-middle text-center">{pedido.nombre_cliente}</td>
        <td className="align-middle text-center">{pedido.nombre_vendedor}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <p>Solo se pueden declarar como entregados los pedidos que tienen estado <i>completado</i>.</p>
      <p>Seleccione el pedido que declarará como entregado.</p>
      <form>
        <div className="row mb-3">
          <div className="col-sm">
            <input type="text" placeholder="Buscar por cliente, vendedor o fecha..." value={busqueda} onChange={(e)=>setBusqueda(e.target.value)} className="form-control" id="busqueda" />
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Seleccionar
              </th>
              <th className="align-middle text-center" scope="col">
                Número
              </th>
              <th className="align-middle text-center" scope="col">
                Fecha
              </th>
              <th className="align-middle text-center" scope="col">
                Cliente
              </th>
              <th className="align-middle text-center" scope="col">
                Vendedor
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
