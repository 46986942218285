import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Modificar cliente";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([])
  const [busqueda, setBusqueda] = useState("")

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("xd02/lista/", {})
      .then((json) => {
        setClientes(json.clientes)
        setClientesFiltrados(json.clientes)
        setEstado(ESTADOS.CARGADO)
      })
      .catch(e=>{alert("No han podido ser cargados los clientes correctamente: "+e.message);setEstado(ESTADOS.CARGADO)})
  }, []);

  useEffect(()=>{
    let filtrado = clientes.filter((fila)=>{
      if (fila.nombre.toUpperCase().includes(busqueda.toUpperCase()) || fila.dni.includes(busqueda) || fila.telefono.includes(busqueda)) {
        return true;
      }
      return false;
    })
    setClientesFiltrados(filtrado)
  }, [busqueda])

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="4">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = clientesFiltrados.map((cliente) => (
      <tr>
        <td class="align-middle text-center">{cliente.nombre}</td>
        <td class="align-middle text-center">{cliente.telefono}</td>
        <td class="align-middle text-center">{cliente.dni}</td>
        <td class="align-middle text-center">
          <Link to={"modificar/" + cliente.id_cliente} role="button" class="btn btn-primary">
            Seleccionar
          </Link>
        </td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form>
        <div class="row mb-3">
          <div class="col-sm">
            <input type="text" placeholder="Buscar por nombre, teléfono o DNI..." value={busqueda} onChange={(e)=>setBusqueda(e.target.value)} class="form-control" id="busqueda" />
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th class="align-middle text-center" scope="col">
                Nombre
              </th>
              <th class="align-middle text-center" scope="col">
                Teléfono
              </th>
              <th class="align-middle text-center" scope="col">
                DNI
              </th>
              <th class="align-middle text-center" scope="col">
                Seleccionar
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
