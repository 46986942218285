import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Comisiones Totales";

  const [estadoTabla, setEstadoTabla] = useState(ESTADOS.CARGADO);
  const [comisiones, setComisiones] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [total, setTotal] = useState(0);
  const [idUsuario, setIdUsuario] = useState();
  const [filtroEstado, setFiltroEstado] = useState("todos")

  const navigate = useNavigate();
  const peticion = usePeticion();

  const fechaActual = new Date();
  // const fechaHoy = fechaActual.getFullYear() + "-" + (fechaActual.getMonth() + 1).toString().padStart(2, '0') + "-" + fechaActual.getDate().toString().padStart(2, '0')

  const fechaMesAnterior = new Date(fechaActual);
  fechaMesAnterior.setMonth(fechaMesAnterior.getMonth() - 1);
  // const fechaAnterior = fechaMesAnterior.getFullYear() + "-" + (fechaMesAnterior.getMonth() + 1).toString().padStart(2, '0') + "-" + fechaMesAnterior.getDate().toString().padStart(2, '0')

  const [hasta, setHasta] = useState(
    fechaActual.getFullYear() + "-" + (fechaActual.getMonth() + 1).toString().padStart(2, "0") + "-" + fechaActual.getDate().toString().padStart(2, "0")
  );
  const [desde, setDesde] = useState(
    fechaMesAnterior.getFullYear() +
      "-" +
      (fechaMesAnterior.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      fechaMesAnterior.getDate().toString().padStart(2, "0")
  );

  function getComisiones() {
    setEstadoTabla(ESTADOS.CARGANDO);
    let formData = new FormData();
    formData.append("desde", desde);
    formData.append("hasta", hasta);
    formData.append("estado", filtroEstado);
    setTotal(0);
    peticion("comisiones_vendedor/comisiones/", { method: "POST", body: formData }, "Comisiones", "No se han podido cargar las comisiones.").then(
      (json) => {
        setComisiones(json.comisiones);
        let total = 0;
        json.comisiones.forEach((element) => {
          total = total + parseFloat(element.comision);
        });
        setTotal(total);
        setEstadoTabla(ESTADOS.CARGADO);
      }
    );
  }

  useEffect(getComisiones, [desde, hasta, filtroEstado]);

  let contenidoTabla;

  if (estadoTabla === ESTADOS.CARGANDO) {
    contenidoTabla = (
      <tbody>
        <tr>
          <td colSpan={2}>
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    );
  } else if (estadoTabla === ESTADOS.CARGADO) {
    contenidoTabla = (
      <>
        <tbody>
          {comisiones.map((comision) => (
            <tr>
              <td>{(new Date(comision.fecha).toISOString().split('T')[0].split('-').reverse().join('/'))}</td>
              <td>{comision.comision}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th scope="col">TOTAL</th>
            <th scope="col">{total.toFixed(2)}</th>
          </tr>
        </tfoot>
      </>
    );
  }  

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <p>Estas son las comisiones totales de todos los usuarios a pedido de Janeth.</p>
      <form className="g-3 row mb-3" onSubmit={getComisiones}>
          <div className="col-12 col-sm-4">
            <label htmlFor="desde" class="form-label">
              Desde
            </label>
            <input type="date" className="form-control" value={desde} id="desde" name="desde" onChange={(e) => setDesde(e.target.value)} />
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="hasta" class="form-label">
              Hasta
            </label>
            <input type="date" className="form-control" value={hasta} id="hasta" name="hasta" onChange={(e) => setHasta(e.target.value)} />
          </div>
          <div class="col-12 col-sm-4">
            <label htmlFor="hasta" class="form-label">
              Estado de pedidos
            </label>
            <select class="form-select" value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
              <option value="todos">Todos</option>
              <option value="abierto">Abierto</option>
              <option value="completado">Completado</option>
              <option value="entregado">Entregado</option>
            </select>
          </div>
        </form>
        <table class="table table-bordered table-sm align-middle text-center">
          <thead>
            <tr>
              <th scope="col">Fecha</th>
              <th scope="col">Comisiones</th>
            </tr>
          </thead>
          {contenidoTabla}
        </table>
    </main>
  );
}
