import { useRouteError, useNavigate } from "react-router-dom";

export function Error(props) {
  const error = useRouteError();
  console.error(error);

  let titulo;

  if (!navigator.onLine) {
    titulo = "No estás conectado a internet";
  } else if (props.tipo == "404") {
    titulo = "Página no encontrada";
  } else {
    titulo = "Ha ocurrido un error :(";
  }

  const navigate = useNavigate();

  return (
    <main className="container p-3">
      <div className="alert alert-danger">
        <h4 className="alert-heading">{titulo}</h4>

        {navigator.onLine
          ? "Vuelve a intentar realizar la operación, comprueba tu conexión a internet o de lo contrario escribele a Pablo y envíale una captura de pantalla."
          : "No estás conectado a internet, comprueba tu conexión e intenta nuevamente. De lo contrario por favor comunícate con análisis de sistemas."}
        <br />
        <br />
        {error?.statusText || error?.message}
      </div>
      <a target="_blank" href="https://wa.me/51998884006" className="btn btn-success mb-3 me-3" role="button">
        <i className="bi bi-whatsapp"></i> WhatsApp
      </a>
      <button type="button" onClick={() => window.location.reload()} className="btn btn-primary mb-3 me-3">
        <i className="bi bi-arrow-clockwise"></i> Actualizar
      </button>
      <button type="button" onClick={() => navigate(-1)} className="btn btn-secondary mb-3 me-3">
        <i className="bi bi-arrow-left"></i> Regresar
      </button>
    </main>
  );
}

export function Exito(props) {
  const navigate = useNavigate();

  return (
    <>
    // <main className="container p-3">
      <div className="alert alert-success">
        <h4 className="alert-heading">{props.titulo}</h4>
        {props.mensaje}
      </div>
      {props.children}
    // </main>
    </>
  );
}
