import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Agregar nuevo cliente";

  const [estado, setEstado] = useState(ESTADOS.CARGADO);
  const [dni, setDni] = useState("");
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [direccion, setDireccion] = useState("")

  const [estadoBusquedaDNI, setEstadoBusquedaDNI] = useState("cargado")

  const navigate = useNavigate();
  const peticion = usePeticion();

  function consultarDatosPorDNI() {
    setEstadoBusquedaDNI("buscando")
    peticion(
      "xd01/consultar_datos_por_dni/" + dni,
      {}).then(json=>{
        setNombreCompleto(json.data.nombre_completo);
        setDireccion(json.data.direccion_completa);
        setEstadoBusquedaDNI("cargado")
      }).catch(e=>setEstadoBusquedaDNI("cargado"))
  }

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO)
    let formData = new FormData(e.currentTarget)
    peticion("xd01/proceso/", {
      method: "POST",
      body: formData
    })
    .then(json=>{
      if (json.accion == "dni ya existente") {
        alert("Este DNI ya ha sido registrado anteriormente")
        setEstado(ESTADOS.CARGADO)
      } else {
        setEstado(ESTADOS.EXITO)
      }
    })
    .catch(e=>alert("El cliente no ha podido ser agregado correctamente: "+e.message))
  }

  function resetear() {
    setNombreCompleto("")
    setDireccion("")
    setDni("")
    setEstado(ESTADOS.CARGADO)
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <form method="post" className="row g-3" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-md-4">
          <label htmlFor="dni" className="form-label">
            DNI
          </label>
          <div className="input-group">
            <input type="tel" className="form-control" id="dni" name="dni" onChange={(e) => setDni(e.target.value)} value={dni}  />
            <button className="btn btn-outline-primary" onClick={consultarDatosPorDNI} type="button">
              {estadoBusquedaDNI == "cargado"? <><i className="bi bi-arrow-right-square"></i> Rellenar</> : <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Rellenando...</>}
            </button>
          </div>
        </div>
        <div className="col-md-8">
          <label htmlFor="nombre" className="form-label" required>
            Nombre
          </label>
          <input type="text" required className="form-control" name="nombre" id="nombre" value={nombreCompleto} onChange={(e) => setNombreCompleto(e.target.value)} />
        </div>
        <div className="col-md-12">
          <label htmlFor="destino" className="form-label">
            Dirección
          </label>
          <textarea rows={2} className="form-control" value={direccion} onChange={e=>setDireccion(e.target.value)} id="destino" name="destino" />
        </div>
        <div className="col-md-6">
          <label htmlFor="saldo" className="form-label">
            Saldo
          </label>
          <input type="number" step="0.01" className="form-control" id="saldo" name="saldo" defaultValue="0" />
        </div>
        <div className="col-md-6">
          <label htmlFor="telefono" className="form-label">
            Teléfono
          </label>
          <input type="tel" className="form-control" id="telefono" name="telefono" />
        </div>
        <div className="col-md-6">
          <label htmlFor="agencia" className="form-label">
            Agencia
          </label>
          <input type="text" className="form-control" id="agencia" name="agencia" />
        </div>
        <div className="col-md-6">
          <label htmlFor="tipo_entrega" className="form-label">
            Tipo de entrega
          </label>
          <select className="form-select" name="tipo_entrega" id="tipo_entrega">
            <option value="No especificado">
              No especificado
            </option>
            <option value="Recojo">Recojo</option>
            <option value="Envío">Envío</option>
          </select>
        </div>
        <div className="col-md-12">
          <label htmlFor="observaciones" className="form-label">
            Observaciones
          </label>
          <textarea className="form-control" id="observaciones" name="observaciones" rows="5"></textarea>
        </div>
        <div className="col">
          <button type="submit" className="btn btn-success me-3">
            Agregar cliente
          </button>
          <button type="button" onClick={()=>navigate(-1)} className="btn btn-danger">
            Cancelar
          </button>
        </div>
      </form>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = <Exito titulo="Cliente agregado con éxito" mensaje="El cliente ha sido agregado de forma satisfactoria">
      <button type="button" className="btn btn-primary" onClick={resetear}>Agregar otro cliente</button>
    </Exito>
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
