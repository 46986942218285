import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion } from "../herramientas";

export default function Index() {
  const [estado, setEstado] = useState("cargando");
  const [operaciones, setOperaciones] = useState([]);

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("operaciones_usuarios/", {}).then((json) => {
      let asd = [];
      let operacionAnt;
      json.operaciones.forEach((operacion) => {
        if (operacion.modulo != operacionAnt) {
          asd.push(<h2 className="mb-0">{operacion.modulo}</h2>);
        }
        asd.push(
          <div key={operacion.codigo} className="col-12 col-md-6 col-xl-4" style={{ cursor: "pointer" }}>
            <div
              className="card w-100"
              onClick={() => {
                navigate("/" + operacion.codigo);
              }}
            >
              <div className="card-body row w-100">
                <div className="col-auto mb-0 h4">
                  <i className={operacion.grafico_i}></i>
                </div>
                <div className="col text-center d-flex justify-content-center">
                  <h5 className="my-auto align-middle">{operacion.nombre}</h5>
                </div>
              </div>
            </div>
          </div>
        );
        operacionAnt = operacion.modulo;
      });
      setOperaciones(asd);
      setEstado("cargado");
    }).catch(e=>alert("No han podido ser cargadas las operaciones disponibles para el usuario."))
  }, []);

  let contenido;

  if (estado === "cargando") {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    contenido = (
      <div className="container p-0 h-100 mb-3">
        <div className="row g-3">{operaciones}</div>
      </div>
    );
  }

  return (
    <main className="container p-3">
      <h1>Sistema Importaciones Briju</h1>
      {contenido}
    </main>
  );
}
