import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Autorizaciones por usuario";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [usuario, setUsuario] = useState({})
  const [operaciones, setOperaciones] = useState([])

  const navigate = useNavigate();
  const peticion = usePeticion();

  const { id_usuario } = useParams()

  useEffect(() => {
    peticion("su01/modificar_autorizaciones/"+ id_usuario)
      .then((json) => {
        setUsuario(json.usuario);
        setOperaciones(json.operaciones)
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargadas las operaciones correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO)
    let formData = new FormData(e.currentTarget);
    formData.append("id_usuario", id_usuario)
    peticion("su01/modificar_autorizaciones_proceso/", {
      method: "POST", body: formData
    })
    .then(json=>{
      navigate("../")
    })
    .catch(e=>{alert("No ha podido actualizarse las autorizaciones del usuario: "+e); setEstado(ESTADOS.CARGADO)})
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="6">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = operaciones.map((operacion) => (
      <tr>
        <td className="align-middle text-center">{operacion?.modulo}</td>
        <td className="align-middle text-center">{operacion.codigo}</td>
        <td className="align-middle text-center">
          <input className="form-check-input" type="checkbox" name={operacion["codigo"]} defaultChecked={operacion.existe} defaultValue={operacion.existe ? 2 : 1} />
        </td>
        <td className="align-middle text-center">{operacion.nombre}</td>
        <td className="align-middle text-center">{operacion.descripcion}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form onSubmit={handleSubmit}>

      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Módulo
              </th>
              <th className="align-middle text-center" scope="col">
                Código
              </th>
              <th className="align-middle text-center" scope="col"></th>
              <th className="align-middle text-center" scope="col">
                Operación
              </th>
              <th className="align-middle text-center" scope="col">
                Descripción
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
      <Link to="../" role="button" className="btn btn-secondary me-3 mb-3">Regresar</Link>
      <button className="btn btn-warning me-3 mb-3" type="submit">Guardar autorizaciones</button>
      </form>
    </main>
  );
}
