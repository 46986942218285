import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Modificar pedido abierto";

  const { id_pedido } = useParams();

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);

  const [datos, setDatos] = useState({});

  const [clientes, setClientes] = useState([]);
  const [clientesFiltrados, setClientesFiltrados] = useState([]);
  const [busquedaCliente, setBusquedaCliente] = useState("");

  const [cliente, setCliente] = useState({ id_cliente: 0, nombre: "" });
  const [agencia, setAgencia] = useState("");
  const [destino, setDestino] = useState("");
  const [costoEnvio, setCostoEnvio] = useState(5.0);
  const [tipoEntrega, setTipoEntrega] = useState("");

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("va02/modificar_datos_pedido/" + id_pedido, {})
      .then((json) => {
        setClientes(json.clientes);
        setClientesFiltrados(json.clientes);
        setDatos(json.datos);
        setCliente({ id_cliente: json.datos.id_cliente, nombre: json.datos.nombre_cliente });

        setTipoEntrega(json.datos.tipo_entrega);
        if (json.datos.tipo_entrega == "Envío") {
          setAgencia(json.datos.agencia);
          setDestino(json.datos.destino);
          setCostoEnvio(json.datos.costo_envio);
        } else {
          json.clientes.forEach((cliente) => {
            if (cliente.id_cliente == json.datos.id_cliente) {
              setAgencia(cliente.agencia);
              setDestino(cliente.destino);
              setCostoEnvio(5.0);
            }
          });
        }
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => alert("No se ha podido cargar los datos del pedido a modificar: " + e));
  }, []);

  useEffect(() => {
    let filtrado = clientes.filter((fila) => {
      if (fila.nombre.toUpperCase().includes(busquedaCliente.toUpperCase()) || fila.dni.includes(busquedaCliente) || fila.telefono.includes(busquedaCliente)) {
        return true;
      }
      return false;
    });
    setClientesFiltrados(filtrado);
  }, [busquedaCliente]);

  function handleSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    formData.append("id_pedido", id_pedido);
    peticion("va02/comprobar_existencia_pedido_cliente/" + cliente.id_cliente + "/" + id_pedido)
      .then((json) => {
        if (json.cantidad > 0) {
          alert("Este cliente ya tiene un pedido abierto, no puede tener otro.");
        } else {
          setEstado(ESTADOS.CARGANDO);
          peticion("va02/modificar_datos_pedido_proceso/", {
            method: "POST",
            body: formData,
          })
            .then((json) => {
              navigate("../modificar/" + id_pedido);
            })
            .catch((e) => alert("El pedido no ha podido ser modificado correctamente: " + e.message));
        }
      })
      .catch((e) => alert("No se ha podido comprobar si ya exite otro pedido abierto para este cliente: " + e));
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <form method="post" onSubmit={handleSubmit} className="row g-3">
          <input type="hidden" name="id_cliente" value={cliente.id_cliente} />
          <div className="col-md-8">
            <label className="form-label">Cliente</label>
            <div className="input-group">
              <input type="text" readOnly disabled className="form-control" value={cliente.nombre} />
              <button className="btn btn-primary" type="button" data-bs-toggle="modal" data-bs-target="#clientesModal">
                <i className="bi bi-search"></i> Buscar
              </button>
            </div>
          </div>
          <div className="col-md-4">
            <label for="fecha" className="form-label">
              Fecha
            </label>
            <input type="date" required className="form-control" name="fecha" id="fecha" defaultValue={datos.fecha} />
          </div>
          <div className="col-md-4">
            <label for="tipo_entrega" className="form-label">
              Tipo de entrega
            </label>
            <select className="form-select" id="tipo_entrega" name="tipo_entrega" value={tipoEntrega} onChange={(e) => setTipoEntrega(e.target.value)}>
              <option value="Recojo">Recojo</option>
              <option value="Envío">Envío</option>
            </select>
          </div>
          {tipoEntrega == "Envío" && (
            <>
              <div id="divAgencia" className="col-md-4">
                <label for="agencia" className="form-label">
                  Agencia
                </label>
                <input type="text" className="form-control" id="agencia" name="agencia" defaultValue={agencia} />
              </div>
              <div id="divCostoEnvio" className="col-md-4">
                <label for="costo_envio" required className="form-label">
                  Costo de envío
                </label>
                <input
                  type="number"
                  step="0.01"
                  min="0.00"
                  className="form-control"
                  id="costo_envio"
                  name="costo_envio"
                  defaultValue={costoEnvio}
                />
              </div>
              <div id="divDestino" className="col-md-12">
                <label for="destino" className="form-label">
                  Destino
                </label>
                <input type="text" className="form-control" name="destino" defaultValue={destino} />
              </div>
            </>
          )}
          <div className="col-md-12">
            <label for="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea name="observaciones" className="form-control" rows="5" defaultValue={datos.observaciones}></textarea>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-warning me-3 mb-3">
              Modificar pedido
            </button>
            <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
              Cancelar
            </button>
          </div>
        </form>

        <div className="modal fade" id="clientesModal" tabindex="-1">
          <div className="modal-dialog modal-xl modal-fullscreen-sm-down">
            <div class="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5">Seleccionar cliente</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div className="modal-body d-flex flex-column">
                <div className="mb-3">
                  <input
                    type="text"
                    placeholder="Buscar por nombre, teléfono o DNI..."
                    value={busquedaCliente}
                    onChange={(e) => setBusquedaCliente(e.target.value)}
                    className="form-control"
                    id="busqueda"
                  />
                </div>
                <div class="table-responsive">
                  <table className="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th className="align-middle text-center" scope="col">
                          Seleccionar
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Nombre
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Teléfono
                        </th>
                        <th className="align-middle text-center" scope="col">
                          DNI
                        </th>
                        <th className="align-middle text-center" scope="col">
                          Saldo
                        </th>
                      </tr>
                    </thead>
                    <tbody id="tabla">
                      {clientesFiltrados.map((cliente) => (
                        <tr>
                          <td className="align-middle text-center">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                setAgencia(cliente.agencia);
                                setCliente({ nombre: cliente.nombre, id_cliente: cliente.id_cliente });
                                setDestino(cliente.destino);
                                setTipoEntrega(cliente.tipo_entrega);
                              }}
                            >
                              Seleccionar
                            </button>
                          </td>
                          <td className="align-middle text-center">{cliente.nombre}</td>
                          <td className="align-middle text-center">{cliente.telefono}</td>
                          <td className="align-middle text-center">{cliente.dni}</td>
                          <td className="align-middle text-center">{cliente.saldo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <main className="container p-3">
        <h1>{nombreOperacion}</h1>
        {contenido}
      </main>
    </>
  );
}
