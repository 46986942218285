import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Declarar entrega";

  const { id_pedido } = useParams();

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);

  const [pedido, setPedido] = useState({});

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("vl01n/modificar_observaciones/" + id_pedido, {})
      .then((json) => {
        setPedido(json.pedido)
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => alert("No se ha podido cargar los datos del pedido a modificar: " + e));
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    let formData = new FormData(e.currentTarget);
    formData.append("id_pedido", id_pedido);
    peticion("vl01n/modificar_observaciones_proceso/", {method: "POST", body: formData})
      .then((json) => {
        navigate("../declarar_entrega/"+id_pedido)
      })
      .catch((e) => alert("No se ha podido modificar las observaciones del pedido: " + e));
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <form method="post" onSubmit={handleSubmit} className="row g-3">
          <div className="col-md-12">
            <label for="observaciones" className="form-label">
              Observaciones
            </label>
            <textarea name="observaciones" className="form-control" rows="5" defaultValue={pedido.observaciones}></textarea>
          </div>
          <div className="col-12">
            <button type="submit" className="btn btn-warning me-3 mb-3">
              Modificar observaciones
            </button>
            <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
              Cancelar
            </button>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <main className="container p-3">
        <h1>{nombreOperacion}</h1>
        {contenido}
      </main>
    </>
  );
}
