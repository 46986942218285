import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Exportar pedidos";

  const [archivo, setArchivo] = useState("");
  const [estado, setEstado] = useState(ESTADOS.CARGADO);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO)
    let formData = new FormData(e.currentTarget);
    peticion("zva03/proceso/", {
      method: "POST", body: formData
    })
    .then(json=>{
      if (json.hay_pedidos) {
        setArchivo(json.archivo)
      } else {
        alert("No hay pedidos entre estas fechas.")
      }
      setEstado(ESTADOS.CARGADO)
    })
    .catch(e=>{alert("No ha podido generarse el archivo: "+e); setEstado(ESTADOS.CARGADO)})
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-md-6 col-12">
          <label htmlFor="desde" className="form-label">Desde</label>
          <input type="date" className="form-control" id="desde" name="desde" required />
        </div>
        <div className="col-md-6 col-12">
          <label htmlFor="hasta" className="form-label">Hasta</label>
          <input type="date" className="form-control" id="hasta" name="hasta" required />
        </div>
        <div className="col-md-6 col-12">
          {estado == ESTADOS.CARGADO ?
          <button type="submit" className="btn btn-success mb-3 me-3">Generar archivo</button> : <button id="botonGenerando" className="btn btn-success me-3 mb-3" type="button" disabled>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
          Generando...
        </button>} 
          <a role="button" target="_blank" download="pedidos.xlsx" href={archivo} className={"btn btn-success mb-3 me-3 "+(archivo == "" ? "disabled" : "")}>Descargar</a>
        </div>
      </form>
    </main>
  );
}
