import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Movimiento de inventario";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [cambios, setCambios] = useState([]);
  // const [cambiosFiltrados, setCambiosFiltrados] = useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [peticionesTransito, setPeticionesTransito] = useState(0)
  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    consultar();
  }, []);

  function consultar() {
    setPeticionesTransito((prev => prev + 1))
    let formData = new FormData();
    formData.append("pagina", pagina);
    formData.append("busqueda", busqueda);
    peticion("mb51/", {
      method: "post",
      body: formData,
    })
      .then((json) => {
        setCambios(json.cambios);
        setTotalPaginas(Math.ceil(json.paginas));
        setPeticionesTransito((prev => prev - 1))
      })
      .catch((e) => {
        setPeticionesTransito((prev => prev - 1))
        alert("No han podido ser cargados los cambios de inventario correctamente: " + e.message);
      });
  }

  useEffect(() => {
    setPagina(1);
    consultar();
  }, [busqueda]);

  useEffect(() => {
    consultar();
  }, [pagina]);

  useEffect(() => {
    // let filtrado = cambios.filter((fila) => {
    //   if (fila.nombre.toUpperCase().includes(busqueda.toUpperCase()) || new Date(fila.fecha).toLocaleString().includes(busqueda)) {
    //     return true;
    //   }
    //   return false;
    // });
    // setCambiosFiltrados(filtrado);
  }, [busqueda]);

  function handleAnteriorPagina() {
    if (pagina > 1) {
      setPagina(pagina - 1);
    }
  }

  function handleSiguientePagina() {
    setPagina(pagina + 1);
  }

  let contenido;

  if (peticionesTransito > 0) {
    contenido = (
      <tr>
        <td colSpan="6">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (peticionesTransito <= 0) {
    contenido = cambios.map((producto) => (
      <tr key={producto.id_kardex}>
        <td className="align-middle text-center">{producto.fecha_formateada}</td>
        <td className="align-middle text-center">{producto.nombre}</td>
        <td className="align-middle text-center">{producto.operacion}</td>
        <td className="align-middle text-center">{producto.variacion_buen_estado}</td>
        <td className="align-middle text-center">{producto.variacion_para_liquidacion}</td>
        <td className="align-middle text-center">{producto.variacion_merma}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <div>
        <div className="row mb-0 g-3">
          <div className="col-md">
            <input
              type="text"
              placeholder="Buscar producto por nombre o fecha y hora..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              className="form-control"
              id="busqueda"
            />
          </div>
          <div className="col-md-auto">
            <nav>
              <ul class="pagination">
                {pagina > 1 && (
                  <li class="page-item">
                    <button class="page-link" onClick={() => setPagina(1)}>
                      1
                    </button>
                  </li>
                )}
                {pagina > 3 && (
                  <li class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>
                )}
                {pagina > 2 && (
                  <li class="page-item">
                    <button class="page-link" onClick={handleAnteriorPagina}>
                      {pagina - 1}
                    </button>
                  </li>
                )}
                <li class="page-item active">
                  <span className="page-link">{pagina}</span>
                </li>
                {pagina < totalPaginas - 1 && (
                  <li class="page-item">
                    <button class="page-link" onClick={handleSiguientePagina}>
                      {pagina + 1}
                    </button>
                  </li>
                )}
                {pagina < totalPaginas - 2 && (
                  <li class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>
                )}
                {pagina < totalPaginas && (
                  <li class="page-item">
                    <button class="page-link" onClick={() => setPagina(totalPaginas)}>
                      {Math.ceil(totalPaginas)}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Fecha
              </th>
              <th className="align-middle text-center" scope="col">
                Nombre
              </th>
              <th className="align-middle text-center" scope="col">
                Operación
              </th>
              <th class="align-middle text-center" scope="col">
                Buen estado
              </th>
              <th class="align-middle text-center" scope="col">
                Para liquidación
              </th>
              <th class="align-middle text-center" scope="col">
                Merma
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
