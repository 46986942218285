import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePeticion } from "../herramientas";
import logo from "./logo.jpg";

export default function IniciarSesion(props) {
  const [input, setInput] = useState({ correo: "", contrasena: "" });
  const [alerta, setAlerta] = useState(false);
  const [estado, setEstado] = useState("formulario");

  const peticion = usePeticion();

  const navigate = useNavigate();

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setInput({
      ...input,
      [name]: value,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    setEstado("cargando");

    let formData = new FormData(event.currentTarget);

    peticion(
      "iniciar_sesion/",
      {
        method: "POST",
        body: formData,
      },
      "Iniciar sesión",
      "No ha podido realizarse la autenticación de usuario"
    ).then((json) => {
      localStorage.removeItem("token");
      if (json.respuesta === "sesion iniciada") {
        localStorage.setItem("token", json.token);
        navigate("/smen/");
      } else {
        setAlerta(true);
        setEstado("formulario");
      }
    })
    .catch(e=>{
      setEstado("formulario");
    });
  }

  useEffect(() => {
    localStorage.removeItem("token");
  }, []);

  return (
    <main
      className="text-center"
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "40px",
        paddingBottom: "40px",
        backgroundColor: "#f5f5f5",
        height: "100%",
      }}
    >
      <form onSubmit={handleSubmit} className="m-auto w-100" style={{ maxWidth: "330px", padding: "15px" }}>
        <img className="mb-4" src={logo} alt="" width="200" />
        <h1 className="h3 mb-3 fw-normal">Sistema BrijuModa</h1>
        {alerta ? (
          <div className="alert alert-danger" role="alert">
            Usuario o contraseña incorrecto.
          </div>
        ) : null}
        <div className="form-floating" style={{ position: "relative" }}>
          <input
            type="text"
            className="form-control"
            name="usuario"
            value={input.usuario}
            onChange={handleInputChange}
            id="floatingInput"
            placeholder="usuario"
            style={{ marginBottom: "-1px", borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
          />
          <label htmlFor="floatingInput">Usuario</label>
        </div>
        <div className="form-floating" style={{ position: "relative" }}>
          <input
            type="password"
            className="form-control"
            name="contrasena"
            value={input.contrasena}
            onChange={handleInputChange}
            id="floatingPassword"
            placeholder="Contraseña"
            style={{ marginBottom: "10px", borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          />
          <label htmlFor="floatingPassword">Contraseña</label>
        </div>

        {/* <div className="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me"/> Recuérdame
        </label>
      </div> */}
        <button className="w-100 btn btn-lg btn-primary" type="submit">
          {estado == "formulario" ? "Ingresar" : [<span className="spinner-border spinner-border-sm" role="status"></span>, " Cargando..."]}
        </button>
        <p className="mt-5 mb-3 text-muted">© 2018-2023</p>
      </form>
    </main>
  );
}
