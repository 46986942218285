import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Actualizar inventario producto";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);

  const [variacionBuenEstado, setVariacionBuenEstado] = useState(0)
  const [variacionMerma, setVariacionMerma] = useState(0)
  const [variacionParaLiquidacion, setVariacionParaLiquidacion] = useState(0)

  const [ahoraBuenEstado, setAhoraBuenEstado] = useState(0)
  const [ahoraParaLiquidacion, setAhoraParaLiquidacion] = useState(0)
  const [ahoraMerma, setAhoraMerma] = useState(0)

  const { id_producto } = useParams()

  const [producto, setProducto] = useState({})

  useEffect(()=>{
    peticion("mi04/actualizar_inventario_producto/"+id_producto, {})
    .then((json)=>{
      setProducto(json.producto)
      setEstado(ESTADOS.CARGADO)
    })
    .catch(e=>{
      alert("No han podido ser cargados los datos del producto a actualizar el invetario: "+e)
    })
  }, [])

  const navigate = useNavigate();
  const peticion = usePeticion();

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO);
    let formData = new FormData(e.currentTarget);
    formData.append("id_producto", id_producto)
    peticion("mi04/proceso/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        setEstado(ESTADOS.EXITO);
      })
      .catch((e) => alert("El inventario del producto no ha podido ser modificado de forma satisfactoria: " + e.message));
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (<>
    <p className="lead">{producto["nombre"]}</p>
<form method="post" onSubmit={handleSubmit}>
	<input type="hidden" name="id_producto" defaultValue={producto["id_producto"]} />
	<div className="row g-2 mb-2 text-center">
		<label className="col-3 col-form-label">
		</label>
		<label className="col-3 col-form-label">Antes</label>
		<label className="col-3 col-form-label">Variación</label>
		<label className="col-3 col-form-label">Ahora</label>
	</div>
	<div className="row g-2 mb-2 text-center">
		<label for="variacion_buen_estado" className="col-3 col-form-label">Buen estado</label>
		<div className="col-3">
			<input type="number" id="antes_buen_estado" className="form-control text-center" value={producto["cantidad_buen_estado"]} disabled />
		</div>
		<div className="col-3">
			<input type="number" id="variacion_buen_estado" step="1" name="buen_estado" className="form-control text-center" value={variacionBuenEstado} onChange={e=>setVariacionBuenEstado(e.target.value)} />
		</div>
		<div className="col-3">
			<input type="number" id="ahora_buen_estado" className="form-control text-center" disabled value={parseInt(producto["cantidad_buen_estado"])+parseInt(variacionBuenEstado)} />
		</div>
	</div>

	<div className="row g-2 mb-2 text-center">
		<label for="variacion_para_liquidacion" className="col-3 col-form-label">Para liquidación</label>
		<div className="col-3">
			<input type="number" id="antes_para_liquidacion" className="form-control text-center" value={producto["cantidad_para_liquidacion"]} disabled />
		</div>
		<div className="col-3">
			<input type="number" id="variacion_para_liquidacion" step="1" name="para_liquidacion" className="form-control text-center" value={variacionParaLiquidacion} onChange={e=>setVariacionParaLiquidacion(e.target.value)} />
		</div>
		<div className="col-3">
			<input type="number" id="ahora_para_liquidacion" className="form-control text-center" disabled value={parseInt(producto["cantidad_para_liquidacion"])+parseInt(variacionParaLiquidacion)} />
		</div>
	</div>

	<div className="row g-2 mb-2 text-center">
		<label for="variacion_merma" className="col-3 col-form-label">Merma</label>
		<div className="col-3">
			<input type="number" id="antes_merma" className="form-control text-center" value={producto["cantidad_merma"]} disabled />
		</div>
		<div className="col-3">
			<input type="number" id="variacion_merma" step="1" name="merma" className="form-control text-center" value={variacionMerma} onChange={e=>setVariacionMerma(e.target.value)} />
		</div>
		<div className="col-3">
			<input type="number" id="ahora_merma" className="form-control text-center" disabled value={parseInt(producto["cantidad_merma"])+parseInt(variacionMerma)} />
		</div>
	</div>
	<div className="mb-3">
		<label for="operacion" className="form-label">Operación</label>
		<input type="text" className="form-control" name="operacion" defaultValue="Entrada mercadería" />
	</div>
	<button type="button" className="btn btn-danger me-2 mb-3" onclick="history.back()">Cancelar</button>
	<button type="submit" className="btn btn-warning me-2 mb-3">Actualizar</button>
</form>
    </>);
  } else if (estado === ESTADOS.EXITO) {
    contenido = (
      <Exito titulo="Inventario de producto actualizado con éxito" mensaje="El invetario del producto ha sido actualizado correctamente">
        <Link role="button" className="btn btn-primary" to="../">
          Actualizar invenario de otro producto
        </Link>
      </Exito>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
