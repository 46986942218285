import { useNavigate } from "react-router-dom";
import { API_URL } from "./config";
import { createContext } from "react";

export const ESTADOS = {
  CARGANDO: Symbol(),
  CARGADO: Symbol(),
  ERROR: Symbol(),
  EXITO: Symbol()
}

export function usePeticion() {
  const navigate = useNavigate();
  return function (ruta, opciones = {}, nombreOperacion = "", mensajeError = "") {
    return new Promise((resuelve, rechaza)=>{
      fetch(API_URL + ruta, {
        headers: new Headers({ Authorization: "Bearer " + localStorage.getItem("token"), Accept: "application/json" }),
        ...opciones,
      })
        .then(function (respuesta) {
          return respuesta.json();
        })
        .then(function (json) {
          if (json.estado === "exito") {
            resuelve(json)
          } else if (json.estado === "error") {
            throw new Error(json.error);
          } else if (json.estado === "no autorizado") {
            // navigate("/no_autorizado", { state: { titulo: nombreOperacion } });
          } else if (json.estado === "sesion caducada") {
            localStorage.removeItem("token");
            navigate("/iniciar_sesion");
          }
        })
        .catch((e) => {
          rechaza(e)
        });
    })
  };
}

export function Exito(props) {
  const navigate = useNavigate();

  return (<>
    {/* <main className="container p-3"> */}
      <div className="alert alert-success">
        <h4 className="alert-heading">{props.titulo}</h4>
        {props.mensaje}
      </div>
      {props.children}
    {/* </main> */}
    </>
  );
}
