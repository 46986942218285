import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function Index() {
  const nombreOperacion = "Estadísticas";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [puntaje, setPuntaje] = useState({});
  const [historico, setHistorico] = useState([]);
  const [rankingClientes, setRankingClientes] = useState([]);

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("estadisticas_vendedores/estadisticas/", {}, "Estadísticas", "No se han podido cargar las estadísticas.").then((json) => {
      // setPuntaje(json.puntaje);
      // setHistorico(json.historico);
      // setRankingClientes(json.ranking_clientes);
      setEstado(ESTADOS.CARGADO);
    });
  }, []);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (<>
      {/* <div className="row g-3 mb-3">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-1">Puntaje hoy</h5>
              <p className="display-3 mb-1">{puntaje.puntaje}</p>
              <p className="mb-0">{puntaje.fecha.split("-").reverse().join("/")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12 col-xl-7">
          <div className="card">
            <div className="card-body" style={{ display: "inline-block" }}>
              <h5 className="card-title mb-1">Puntajes últimos 15 días</h5>
              <Bar
                style={{ width: 16, height: 9 }}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={{
                  labels: historico.map((dia) =>
                    new Date(dia.fecha).toLocaleDateString("es-ES", { weekday: "short", day: "2-digit", month: "2-digit" }).replace(/\//g, "/")
                  ),
                  datasets: [
                    {
                      label: "Puntaje por día",
                      data: historico.map((dia) => dia.puntaje),
                      backgroundColor: "rgba(13,110,253,0.67)",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-5">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-1">Puntaje por cliente últimos 15 días</h5>
              <div className="table-responsive overflow-auto" style={{ height: 300 }}>
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Cliente</th>
                      <th scope="col">Puntaje</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rankingClientes.map((cliente) => (
                      <tr key={cliente.id_cliente}>
                        <td>{cliente.nombre}</td>
                        <td>{cliente.puntaje}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </>
    );
  }

  return (
    <main className="container-xl p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
