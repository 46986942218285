import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Consultar producto";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [productos, setProductos] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const [busqueda, setBusqueda] = useState("");

  const navigate = useNavigate();
  const peticion = usePeticion();

  useEffect(() => {
    peticion("mm03/lista/", {})
      .then((json) => {
        setProductos(json.productos);
        setProductosFiltrados(json.productos);
        setEstado(ESTADOS.CARGADO);
      })
      .catch((e) => {
        alert("No han podido ser cargados los productos correctamente: " + e.message);
        setEstado(ESTADOS.CARGADO);
      });
  }, []);

  useEffect(() => {
    let filtrado = productos.filter((fila) => {
      if (fila.nombre.toUpperCase().includes(busqueda.toUpperCase())) {
        return true;
      }
      return false;
    });
    setProductosFiltrados(filtrado);
  }, [busqueda]);

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <tr>
        <td colSpan="7">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = productosFiltrados.map((producto) => (
      <tr>
        <td className="align-middle text-center">
          <Link to={"detalle/" + producto.id_producto} role="button" className="btn btn-primary">
            Detalle
          </Link>
        </td>
        <td className="align-middle text-center">
          <img alt="" src={API_URL + "static/img/productos/" + producto.id_producto + "/principal.jpeg"} className="rounded" style={{ height: "5rem" }} />
        </td>
        <td className="align-middle text-center">{producto.nombre}</td>
        <td className="align-middle text-center">{producto.cantidad_buen_estado}</td>
        <td className="align-middle text-center">{producto.cantidad_para_liquidacion}</td>
        <td className="align-middle text-center">{producto.cantidad_merma}</td>
        <td className="align-middle text-center">{producto.comision}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <form>
        <div className="row mb-3">
          <div className="col-sm">
            <input
              type="text"
              placeholder="Buscar producto por nombre..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              className="form-control"
              id="busqueda"
            />
          </div>
        </div>
      </form>
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th className="align-middle text-center" scope="col">
                Detalle
              </th>
              <th className="align-middle text-center" scope="col">
                Imagen
              </th>
              <th className="align-middle text-center" scope="col">
                Nombre
              </th>
              <th className="align-middle text-center" scope="col">
                Buen estado
              </th>
              <th className="align-middle text-center" scope="col">
                Para liquidación
              </th>
              <th className="align-middle text-center" scope="col">
                Merma
              </th>
              <th className="align-middle text-center" scope="col">
                Comisión
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
