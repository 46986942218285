import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Agregar nuevo producto";

  const [estado, setEstado] = useState(ESTADOS.CARGADO);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function handleSubmit(e) {
    e.preventDefault();
    setEstado(ESTADOS.CARGANDO);
    let formData = new FormData(e.currentTarget);
    peticion("mm11/proceso/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        setEstado(ESTADOS.EXITO);
      })
      .catch((e) => alert("El producto no ha podido ser agregado correctamente: " + e.message));
  }

  function resetear() {
    setEstado(ESTADOS.CARGADO);
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <form method="post" className="row g-3" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-md-8">
          <label for="nombre" className="form-label">
            Nombre
          </label>
          <input type="text" required className="form-control" name="nombre" id="nombre" />
        </div>
        <div className="col-md-4">
          <label for="imagen" className="form-label">
            Imagen
          </label>
          <input accept="image/*" className="form-control" name="imagen" type="file" id="imagen" />
        </div>
        <div className="col-md-4">
          <label for="precio_final" className="form-label">
            Precio final
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="precio_final" id="precio_final" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label for="cantidad_buen_estado" className="form-label">
            Cantidad buen estado
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_buen_estado" id="cantidad_buen_estado" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label for="cantidad_para_liquidacion" className="form-label">
            Cantidad para liquidación
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_para_liquidacion" id="cantidad_para_liquidacion" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label for="cantidad_merma" className="form-label">
            Cantidad merma
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_merma" id="cantidad_merma" defaultValue="0" />
        </div>
        <div className="col-md-12">
          <label for="link" className="form-label">
            Link
          </label>
          <input type="url" className="form-control" name="link" id="link" />
        </div>
        <div className="col-md-12">
          <label for="detalle" className="form-label">
            Detalle
          </label>
          <textarea className="form-control" id="detalle" name="detalle" rows="5" />
        </div>
        <div className="col-12">
          <button type="submit" className="btn btn-success me-3 mb-3">
            Agregar producto
          </button>
          <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
            Cancelar
          </button>
        </div>
      </form>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = (
      <Exito titulo="Producto agregado con éxito" mensaje="El producto ha sido agregado de forma satisfactoria">
        <button type="button" className="btn btn-primary" onClick={resetear}>
          Agregar otro producto
        </button>
      </Exito>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
